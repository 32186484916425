@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800;900&display=swap");
* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  font-family: "Montserrat", sans-serif;
  box-sizing: border-box;
}
.no-spinners::-webkit-inner-spin-button,
.no-spinners::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

.no-spinners {
  -moz-appearance: textfield; /* Firefox */
}

body {
  margin: 0;
  padding: 0;
}
html {
  width: 100% !important;
  background: #e3e3e3;
}

.contHeader {
  position: sticky;
  top: 0;
  width: 100% !important;
  height: 100px;
  background-color: #f9f4f1;
  color: #fff;
  z-index: 999;
}
.contIzquierda {
  position: relative;
  display: flex;
  justify-content: left;
  font-size: 25px;
  padding: 0% !important;
  left: 20px;
  align-items: center;
}

.contGeneral {
  margin-right: 0%;
  display: flex;
  justify-content: center;
}

.contenedor {
  width: 100% !important;
  padding-right: 0 !important;
  padding-left: 0 !important;
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.contDerecha {
  position: relative;
  display: flex;
  justify-content: right;
  font-size: 25px;
  right: 20px;
  align-items: center;
  stroke-width: 1;
}
.contDerecha div {
  position: relative;
  display: flex;
  justify-content: center;
  font-size: 25px;
  align-items: center;
  stroke-width: 1;
}
.contMain {
  background: #e3e3e3;
  height: 100%;
  width: 100%;
}
.contDentHea {
  width: 100% !important;
  height: 100%;
  font-size: 20px;
  font-weight: 700;
}
.imgLogo {
  position: relative;
  top: 5px;
  width: 220px;
}

.imgLogoFormEnv {
  position: relative;
  width: 220px;
  background-color: #fff;
}

.btnVolverTienda {
  border-top: 0px solid transparent;
  border-left: 0px solid transparent;
  border-right: 0px solid transparent;
  border-bottom: 0px solid transparent;
  background-color: #fff;
  text-align: center;
  font-size: 0.8rem;
  font-weight: bold;
  text-transform: uppercase;
  height: 64px;
  padding: 0px;
  outline: none;
}
.btnSeleccion {
  margin-top: 5px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  -webkit-appearance: none;
  height: 45px !important;
  border-top: 0px solid transparent;
  border-left: 0px solid transparent;
  border-right: 0px solid transparent;
  border-bottom: 1px solid transparent;
  background-color: #e9e9ed;
  text-align: center;
  /* color: #85878a; */
  color: #898989;
  font-size: 0.875rem;
  font-weight: bold;
  text-transform: uppercase;
  height: 64px;
  padding: 0px;
  outline: none;
  transition: background-color 0.5s, color 0.5s, border-bottom 0.5s;
}
.btnActivo {
  color: #fff;
  border-bottom: 0.01px solid #000;
  background-color: #f46517;
  /* background-color: #555555;
 */
}
.btnActivoDos {
  color: #fff;
  border-bottom: 2px solid #000;
  background-color: #707070;
}

.btnBorde {
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.txtCarritoFrmEnv {
  font-size: 0.9rem;
  font-weight: 600;
  margin-bottom: 3px;
  line-height: 1rem;
}
.txtCarritoFrmEnvPrice {
  font-size: 0.875rem;
  line-height: 1rem;
  font-weight: 600;
  margin-top: 15px;
  margin-bottom: 0;
  color: #333;
}
.contCartEnv {
  width: calc(100% * 1 / 3.001);
  background-color: #f2f1f1;
}
.formEnv {
  padding-right: 0%;
}
.contCartEnvDos {
  box-sizing: border-box;
  width: 100%;
  flex: auto;
  padding: 30px 40px 20px 40px;
}

.txtTitCartEnv {
  font-size: 1.5rem;
  color: #333;
  font-weight: 600;
}

.contTotalAPagar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.txtTotalAPagar {
  font-size: 1rem;
  color: #333;
  line-height: 1rem;
  font-weight: bold;
}

.imgLogoTitulo {
  position: relative;
  top: 5px;
  border-radius: 50%;
  width: 160px;
}

.txtTitu {
  font-family: "Montserrat", sans-serif;
  font-weight: 500 !important;
  color: #000 !important;
  font-size: 20px;
}

.txtTitulo {
  font-family: "Montserrat", sans-serif;
  font-weight: 500 !important;
  color: #000 !important;
  font-size: 20px;
}

.store__search {
  padding-right: 0%;
  position: relative;
  left: 5px;
  top: 0;
  z-index: 5;
  background-image: url(../assets/search.6e7fb5cd.svg);
  backdrop-filter: blur(4px);
  background-color: #fafafa;
  background-size: 20px 20px;
  background-repeat: no-repeat;
  background-position: 20px;
  margin: 20px 0;
  margin-right: 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  width: 40%;
  max-width: 91%;
  display: flex;
  border: 1px solid #a0a0a0;
  box-sizing: border-box;
  border-radius: 20px;
  line-height: 15px;
}

.store__search input {
  font-size: 16px;
  font-size: 1rem;
  padding: 20px 20px 20px 50px;
  border: 0;
  outline: 0;
  width: 100%;
  background-color: transparent;
  border-radius: 0;
  -webkit-appearance: textfield;
}
.store__categories {
  padding-left: 0%;
  position: relative;
  top: 6px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: left;
}

.store__categories button {
  font-family: "Montserrat", sans-serif;
  text-decoration: none;
  border: 0;
  height: 60px;
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  white-space: nowrap;
  margin: 0 5px;
}

.imgCarrito {
  width: 50px;
}
.imgCarritoEnv {
  width: 60px;
}
.contGeneralProduc {
  position: relative;
  background-color: #fafafa;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  height: 100%;
  left: 16px;
  border-radius: 10px;
}
.tituloCont {
  padding-top: 5px;
}
.cart-item-count {
  position: absolute;
  top: -5px;
  right: -4px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #000000;
  color: white;
  font-size: 10px;
}

.btnCartCom {
  padding: 5px 20px;
  background: green;
  color: #fff;
  font-weight: 400;
  font-size: 14px;
  border: none;
  border-radius: 20px;
}
.btnCartCom:hover {
  background: rgb(0, 104, 0);
  color: #fff;
}

.btnCartVac {
  padding: 5px 10px;
  border: 1px solid #7e7e7e;
  color: #000;
  font-weight: 600;
  font-size: 14px;
  border-radius: 20px;
}
.btnCartVac:hover {
  background-color: #d0d0d7;
  color: #000;
}

.btnAddCart {
  background-color: #e85217;
  border-color: #e85217;
  color: #fff;
  height: 38px;
}

.btnAddCart:hover {
  background-color: #c93f08;
  border-color: #c93f08;
  color: #fff;
}
.btnAddCart:active {
  /* background-color: #d35321; */
  background-color: #c93f08;
  border-color: #c93f08;
  color: #fff;
}

.btnCategory {
  position: relative;
  margin-right: 10px;
  border: none;
  cursor: pointer;
  text-decoration: none;
  color: #333;
  font-size: 15px;
  font-weight: 500;
  transition: color 0.3s ease;
}

.contProducGen {
  padding-right: 10px;
  padding-bottom: 30px;
}
.contProductsPre {
  padding: 0%;
  display: flex !important;
  justify-content: center !important;
}

.contInfoPro {
  display: flex;
  justify-content: center;
  padding-right: 0%;
  padding-left: 2%;
}
.contProducGen:hover .contGeneralProduc {
  background-color: #fafafa;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.7);
}

.contProducts {
  padding: 0% 0% 0% 0%;
  margin: 0% 0% 0% 0%;
  display: flex !important;
  justify-content: center !important;
  text-align: center;
}
.contProductsNom {
  display: flex !important;
  justify-content: center !important;
  text-align: center;
}

.contInput {
  display: flex;
  justify-content: right;
}
.contBtnAdd {
  padding: 0%;
  display: flex;
  justify-content: left;
}
.cart-overlay {
  position: fixed;
  height: 100%;
  right: 0px;
  left: 0;
  bottom: 0;
  margin-right: 0% !important;
  padding-right: 0%;
  z-index: 9999;
}

.cart-container {
  display: flex;
  justify-content: right;
  margin: 0%;
  padding-right: 0%;
}

.contCart {
  position: fixed;
  width: 585px;
  height: 100%;
  background-color: #fff;
  z-index: 1;
  transition: box-shadow 0.3s ease-in-out;
}
.contItemsCart {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 450px;
}
.contCart::-webkit-scrollbar {
  width: 10px;
}
.contCart::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Color de fondo de la pista del scroll */
}

.contCart::-webkit-scrollbar-thumb {
  background-color: #888; /* Color del scroll */
}

.contCart::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Color del scroll al pasar el cursor sobre él */
}

.contItemsCarrito {
  display: flex;
  justify-content: center;
  margin: 0%;
  width: 100%;
}

.classFlexCenterGen {
  display: flex;
  justify-content: center;
}

.row {
  margin-right: 10px;
}

/* Style Formulario */

.form-container {
  width: 100%;
  margin: 0 auto;
}

.form-group {
  width: 200px;
  margin-bottom: 5px;
}
.inputBuscarSocio {
  border: none !important;
}
.inputBuscarSocio:focus-visible {
  outline: none !important;
  box-shadow: none !important;
}
.contBuscarSocio {
  border: 1px solid #cccccc;
  border-radius: 5px;
  text-align: center;
}

.inputCentrado {
  text-align: center;
}

.contBuscarSocio:focus-within {
  outline: 1px solid #e85217;
  box-shadow: none;
  border: none;
}

.form-input:focus-visible {
  outline: 1px solid #e85217;
  box-shadow: none;
  border: none;
}

.form-label {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 0.875rem !important;
}

.radio-label {
  margin-right: 10px;
  font-weight: bold;
}

.radio-input {
  position: relative;
  width: 20px;
  height: 20px;
  border: 2px solid #666;
  border-radius: 50%;
  margin-right: 20px;
  flex-shrink: 0;
}

.form-input {
  height: 40px !important;
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 0.875rem;
}

.form-button {
  background-color: #4caf50;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.form-button:hover {
  background-color: #45a049;
}

.form-close {
  background-color: #6c757d;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.form-close:hover {
  background-color: #6c756d;
}

.contenedorBotones {
  display: flex;
  padding-top: 15px;
  justify-content: space-between;
  align-items: center;
}

.contOptions {
  display: flex;
  justify-content: center;
  font-size: 15px;
}

.contTipoOptions {
  display: flex;
  justify-content: left;
  padding-left: 0%;
  padding-right: 0%;
}

.contTotalPag {
  font-weight: 600;
  display: flex;
  justify-content: right;
}

/* Select Group */
.btnTienda {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #e85217;
  margin: 10px;
  width: 170px;
  height: 44px;
  margin: 12px;
  margin-right: 0;
  font-size: 16px;
  font-weight: 600;
  border: none;
  color: #fff;
  border-radius: 5px;
}

.btnTienda:hover {
  background-color: #be2900;
}
.btnTiendaDisabled {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 10px;
  margin-right: 0;
  width: 170px;
  height: 44px;
  color: #fff;
  background: #8a9194;
  font-size: 16px;
  font-weight: 600;
  border: none;
  border-radius: 5px;
}

.imgProduct {
  width: 300px;
  height: 300px;
}

.imgAgus {
  width: 300px;
  max-width: 100%;
  height: auto;
  z-index: 1;
}

.diagonal-div {
  position: relative;
  width: 100%;
  padding-top: 10.5%;
  overflow: hidden;
  left: 10px;
}

.diagonal-div::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #e85217; /* Color de fondo del div */
  transform-origin: top right;
  transform: skewY(-5deg);
}
.contCateg {
  margin-bottom: 25px;
  border: 1px solid #e4e4e4;
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 5px;
  max-height: 190px;
  overflow-y: scroll;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
}

/* nav */
nav {
  display: flex;
  justify-content: right;
  align-items: flex-start;
  margin-bottom: 10px;
  margin-left: 5px;
}

.menu-toggle {
  display: none;
  background: transparent;
  font-size: 1.5rem;
  color: #000;
  cursor: pointer;
}

.nav-menu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  list-style: none;
  margin-left: 10px;
  border-radius: 0 20px 20px 20px;
  z-index: 1;
}
.ulListCart {
  overflow-y: auto;
}

.nav-menu li {
  margin: 5px 0;
}
.txtProCart {
  color: #000;
  font-size: 14px;
  width: 100%;
}
.txtInfoTransfe {
  font-weight: 600;
}
.separadorFormulario {
  margin: 40px 0 40px 0;
  width: 100%;
}

.txtTotalPag {
  margin-top: 10px;
  color: #000;
  display: flex;
  justify-content: left;
  position: relative;
  bottom: 6px;
  font-weight: 600;
  font-size: 14px;
  left: 9px;
}
.iconX {
  color: #000;
  font-size: 25px;
  z-index: 999;
}
.txtCartVa {
  color: #000;
  font-size: 25px;
  position: relative;
  left: 5%;
  padding-top: 20px;
  padding-bottom: 20px;
}
.buttonCartOpenEnv {
  display: none;
}
.imgTranfCel {
  display: none;
  width: 100%;
}
.imgTranfPc {
  display: block;
  width: 100%;
}
@media screen and (max-width: 768px) {
  .noItemsBanner{
    max-width: 100% ;
    padding-left: 5%;
    height: 30vh !important;
  }
  .separadorFormulario {
    margin: 15px 0 10px 0;
  }
  .imgTranfCel {
    display: block;
  }
  .imgTranfPc {
    display: none;
  }
  .form-label {
    padding-top: 0% !important;
  }
  .txtCarritoFrmEnv {
    font-size: 0.785rem;
  }
  .contItemsCart {
    max-height: 90%;
  }
  .txtCarritoFrmEnvPrice {
    font-size: 0.675rem;
    margin-top: 3px;
  }
  .contCartEnv {
    width: 100%;
  }
  .txtTitCartEnv {
    font-size: 1rem;
  }
  .buttonCartOpenEnv {
    display: block;
    margin-top: 30px;
    margin-bottom: 10px;
    border-radius: 4px;
    width: 100%;
    background-color: #fff;
    line-height: 34px;
    border: 1px solid #c4c4c4;
    color: #333;
    font-weight: 600;
    font-size: 0.8rem;
    text-transform: uppercase;
  }
  .displayNoneEnv {
    display: none;
  }
  .menu-toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #a0a0a0;
    margin-left: 8px;
    font-size: 15px;
    background-color: #f9f4f1;
    box-sizing: border-box;
    border-radius: 20px;
    width: 150px;
    line-height: 45px;
  }

  nav {
    flex-direction: column;
  }

  .nav-menu {
    display: none;
    position: static; /* Cambia a posición estática en lugar de fija */
    top: 0; /* Reinicia la posición vertical */
    left: 0; /* Reinicia la posición horizontal */
    transform: none; /* Reinicia la transformación */
    width: 100%; /* Ajusta el ancho del menú en dispositivos móviles */
  }

  .nav-menu.open {
    display: flex;
  }

  .nav-menu h4 {
    margin: 5px;
  }
}

.contBtnElim {
  display: flex;
  justify-content: right;
  padding-top: 10px;
}

.contDescArtCart {
  min-width: 525.25px;
  display: flex;
  justify-content: left;
  align-items: center;
}

.contBtnIniCom {
  display: flex;
  justify-content: right;
  padding-right: 0;
}

.txtStkPro {
  padding-top: 10px;
  padding-bottom: 5px;
  font-size: 16px;
  font-weight: 500;
}
.txtNomProduc {
  font-weight: 500;
  max-height: 100%;
  font-size: 14.3px;
}

.txtPreProduct {
  padding-top: 10px;
  padding-bottom: 5px;
  font-size: 20px;
  font-weight: 700;
}

.btnCelular {
  display: none;
}

.txtBorrarFilDos {
  font-weight: 600 !important;
  height: 47px !important;
  cursor: pointer !important;
  padding-top: 5px !important;
  display: none;
}

.txtBorrarFil {
  font-weight: 600 !important;
  border: 1px solid #a0a0a0 !important;
  background-color: #fff !important;
  box-sizing: border-box !important;
  border-radius: 20px !important;
  width: 12% !important;
  margin-top: 8px;
  height: 59px !important;
  margin-right: 10px !important;
  cursor: pointer !important;
  text-decoration: none !important;
  padding-top: 5px !important;
  margin-bottom: 10px !important;
}
.txtBorrarFil:hover {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5) !important;
}

.contUbi {
  display: flex;
  flex-direction: column;
}
.txtLight{
  font-size: 15px;
  cursor: pointer;
  padding-left: 10px;
}
.iconLight{
  cursor: pointer;
  font-size: 25px;
}

.contCent{
  display: flex;
  justify-content: right;
  align-items: center;
}

@media (max-width: 1055px) {
  .diagonal-div {
    padding-top: 14.5%;
  }
}

@media (max-width: 768px) {
  .txtLight{
    display: none;
  }
  .btnSeleccion {
    font-size: 0.655em;
  }
  .contDescArtCart {
    min-width: 0;
  }
  .contCart {
    width: 65%;
  }
  .contCartEnvioCel {
    width: 80%;
    height: 100%;
    background-color: #fff;
    z-index: 1;
    transition: box-shadow 0.3s ease-in-out;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .contCartEnvioCel::-webkit-scrollbar {
    width: 10px;
  }
  .contCartEnvioCel::-webkit-scrollbar-track {
    background-color: #f1f1f1; /* Color de fondo de la pista del scroll */
  }

  .contCartEnvioCel::-webkit-scrollbar-thumb {
    background-color: #888; /* Color del scroll */
  }

  .contCartEnvioCel::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Color del scroll al pasar el cursor sobre él */
  }
  .btnCartVac {
    font-size: 12px;
  }
  .btnCartCom {
    font-size: 12px;
  }
  .txtProCart {
    font-size: 12px;
  }
  .iconCart {
    font-size: 20px;
  }
  .cart-item-count {
    right: -3px;
    top: 1px;
    width: 14px;
    height: 14px;
  }
  .contDerecha {
    right: 5px;
  }
  .txtTotalPag {
    font-size: 13px;
  }
  .iconX {
    font-size: 18px !important;
  }
  .txtCartVa {
    font-size: 13px;
  }
  .txtVolTien {
    font-size: 13px;
  }
}
.boton-flotante {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 6;
  width: 50px;
  height: 50px;
  font-size: 30px;
  border-radius: 50%;
  background-color: #4caf50;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}
.contFormularioGeneral {
  height: 100vh;
  max-height: 100%;
}

.boton-flotante-dos {
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 6;
  width: 50px;
  height: 50px;
  font-size: 30px;
  border-radius: 50%;
  background-color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.footerTienda {
  background-color: #f9f4f1;
  position: static;
  bottom: 0;
  width: 100%;
  color: #fff;
  padding: 20px;
  z-index: 999;
}
.form-select {
  background-position: right 0.3rem center !important;
}
.form-label {
  color: #666 !important;
}
textarea {
  height: 60px !important;
}

.cardEntrega {
  border: 1px solid #ccc;
  padding: 10px;
  margin: 10px;
  transition: box-shadow 0.3s, border-left 0.3s;
  width: 95%;
  cursor: pointer;
}

.checked {
  background-color: #e6e6e6;
  border-left: 5px solid #e85217ee;
  box-shadow: -5px 0 5px -5px rgba(0, 0, 0, 0.5);
}

@media (max-width: 576px) {
  .noItemsBanner{
    height: 35vh !important;
  }
  .form-label {
    font-size: 0.8rem !important;
    padding-top: 10px;
  }
  .radio-input {
    width: 17px !important;
  }
  .form-input {
    height: 54px;
    font-size: 0.88rem !important;
  }

  .contGeneralProduc {
    background-color: #f5f5f5;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
  }
  .contenedorBotones {
    flex-direction: column;
  }
  .contProductsNom {
    padding-right: 0%;
  }
  .btnCelular {
    display: block;
  }
  .btnPc {
    display: none;
  }
  .store__categories {
    justify-content: right;
    position: fixed;
    top: 40px;
    height: 100%;
    width: 65%;
    margin-right: 0% !important;
    padding-right: 25px;
    padding-left: 25px;
    z-index: 9999;
  }
  .contCateg {
    border: 1px solid #e4e4e4;
    width: 100%;
    background-color: transparent;
  }
  .contTotalPag {
    padding-top: 5px;
    justify-content: center;
  }
  .contTipoOptions {
    justify-content: left;
    padding-right: 0% !important;
  }
  .contBtnIniCom {
    justify-content: center;
  }
  .txtStkPro {
    font-size: 12px;
  }
  .selectStk {
    font-size: 10px;
  }
  .txtNomProduc {
    max-height: 100%;
    font-size: 10px;
  }
  .txtPreProduct {
    font-size: 15px;
  }

  .btnAddCart {
    height: 27px;
    font-weight: 600;
    width: 100px;
    padding: 0;
    font-size: 10.5px;
  }

  .imgProduct {
    width: 171px;
    height: 171px;
  }

  .contCart {
    width: 65%;
  }
  .imgLogo {
    width: 160px;
  }
  .txtTitu span {
    font-size: 12px !important;
  }
  .txtTitu img {
    width: 20px !important;
    padding-bottom: 3px;
  }
  .txtTitu {
    font-size: 12px;
    padding-top: 7px;
    font-weight: 600 !important;
  }
  .iconoWhat {
    font-size: 15px !important;
    font-weight: 600 !important;
  }
  .txtTitulo {
    font-size: 15px;
  }
  .contIzquierda {
    justify-content: left;
  }
  .contCentro {
    justify-content: center;
    left: 0;
  }
  .store__search {
    width: 100%;
    border: 1px solid #a0a0a0;
    margin-right: 10px;
    box-sizing: border-box;
    border-radius: 20px;
    line-height: 15px;
  }

  .txtBorrarFil {
    display: none;
  }
  .txtBorrarFilDos {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #a0a0a0;
    margin-left: 8px;
    font-size: 15px;
    color: #000;
    margin-top: 6px;
    padding-bottom: 5px;
    background-color: #f9f4f1;
    box-sizing: border-box;
    border-radius: 20px;
    width: 150px;
    line-height: 40px;
  }
  .paddBtn {
    padding-bottom: 5px;
  }
  .contProducGen {
    padding-right: 0%;
  }
  .contNavvv {
    display: flex;
    justify-content: center;
    padding-right: 0%;
    margin-right: 0%;
    width: 100%;
  }
}
.noItemsBanner {
  max-width: 98%;
  height: 100%;
}

.noItemsBanner img {
  max-width: 100%;
  max-height: 100%;
}

@media (max-width: 520px) {
  .diagonal-div {
    padding-top: 20%;
  }
  .contSelectG {
    height: 370px;
  }
}

.offcanvas {
  background-color: transparent !important;
}

.carousel-indicators [data-bs-target] {
  width: 5px !important;
}

.carousel-control-next {
  justify-content: right !important;
  padding-right: 15px;
}
.carousel-control-prev {
  justify-content: left !important;
  padding-left: 15px;
}

.carousel-indicators {
  margin-bottom: 0% !important;
}

@media (max-width: 767px) {
  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    display: none !important;
  }
}

